import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { Link } from "gatsby";
import Layout from "../components/base-structure/Layout";
import Secondary from "../components/features/Secondary";
import time from "../images/time.svg";
import chat from "../images/chat.svg";
import chip from "../images/chip.svg";
import analytics from "../images/analyticOne.svg";
import envelop from "../images/envelope.svg";
import knowledge from "../images/knowledge.svg";
import conversation from "../images/conversation.svg";
import Seo from "../components/common/SEO";
import "../styles/Features.scss";
import Customizable from "../components/home/Customizable";

const content = [
  {
    id: 1,
    metaTitle: "Best Features to Manage Customer Support - Invocom",
    metaDescription:
      "We have everything you need to manage your business - Swift integration, NLP support, AI intent based chat flows, smart chatbot analytics and much more. ",
  },
];
export default function Features() {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="features/"
          />
        </div>
      ))}
      <Box className="featurePage">
        <Box className="featureBanner">
          <Box className="featureBannerHeading">
            <Typography variant="h1">
              Smoothly automate your{" "}
              <span style={{ color: "#FCB92B" }}>conversations</span> with
              customers
            </Typography>
          </Box>
          <Box className="featureFormHeading">
            <Typography variantMapping="p">
              Integrate InvoCom into your business to improve customer service,
              increase efficiency, and boost sales
            </Typography>
            <Box className="trial_Btn_wrapper">
              <Link to="https://app.invocom.io/signup/" target="_blank">
                <Button className="trial_btn" id="trial_btn">
                  Free 14-Days Trial
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Secondary
        class="wrapperBox"
        image={time}
        title="Business manager tool"
        paragraph="It is a comprehensive solution for all your business assets i.e aligning Leads & improved user experience"
      />
      <Secondary
        class="wrapperBoxTwo"
        image={chat}
        title="Live chat"
        paragraph="Deliver customers a real-time response while ensuring high-quality customer service"
      />
      <Secondary
        class="wrapperBox"
        image={chip}
        title="AI-intent bases chat flows"
        paragraph="Effortless chatbot conversations handles bulk FAQs, gather leads, and entertain inquires"
      />
      <Box className="featureRevenue">
        <Typography variant="h2">
          Accelerate your revenue to 10x with a purpose-built AI chatbot
        </Typography>
      </Box>
      <Secondary
        class="wrapperBoxThree"
        image={analytics}
        title="Robust performance analytics"
        paragraph="The quantitative real-time analysis provides credible performance analytics. The data is readily available and exported in CSV format. With the help of these reports, you can analyze how many customers are talking to the AI bot."
      />
      <Secondary
        class="wrapperBoxFour"
        image={envelop}
        title="Email support"
        paragraph="Automated AI support can quickly respond to incoming email leads, using the innovative tech the bot can produce customized email responses to get engaged with potential leads."
      />
      <Secondary
        class="wrapperBoxThree"
        image={knowledge}
        title="Knowledge base"
        paragraph="A knowledge base is a help and resource center, it acts as a useful resource to guide and connect with consumers in response to any query."
      />
      <Secondary
        class="wrapperBoxFour"
        image={conversation}
        title="Collaborate and assign conversations"
        paragraph={
          <ul>
            <li>Custom bot</li>
            <li>Ticketing system</li>
            <li>Tag and assign chat</li>
            <li>Calendar integration</li>
            <li>Conversation dashboard</li>
            <li>Smart assign</li>
          </ul>
        }
      />
      <Box className="featureAccelerate">
        <Typography variant="h2">All you want is right here!</Typography>
        <Typography variant="h6">
          Go above & beyond with advanced Chatbot and grow your business faster
        </Typography>
      </Box>
      <Box className="featureDemoForm">
        <Customizable indexprop={3} pointsColor="#232323" />
      </Box>
    </Layout>
  );
}

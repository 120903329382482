import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { Link } from "gatsby";
import DoneIcon from "@mui/icons-material/Done";
import "../home/Customizable.scss";

export default function Customizable(props) {
  const { indexprop, title, background, textColor, pointsColor } = props;
  return (
    <Box
      className={
        indexprop === 1
          ? `customizeBack_Home`
          : indexprop === 2
          ? `customizeBack_About`
          : indexprop === 3
          ? `customizeBack_Feature`
          : indexprop === 4
          ? `customizeBack_Category`
          : indexprop === 5
          ? `customizeBack_Author`
          : ""
      }
      style={{ backgroundColor: `${background}` }}
    >
      <Container>
        <Box className="customizable">
          <Typography variant="h2" style={{ color: `${textColor}` }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Box className="buttons_Div_Two">
            <Link to="https://app.invocom.io/signup/" target="_blank">
              <Button className="free_Trial" id="free_Trial_btn">
                Start Free Trial
              </Button>
            </Link>
            <Link to="https://calendly.com/invocom/book-a-demo" target="_blank">
              <Button className="free_Demo" id="free_Demo_btn">
                Book a free demo
              </Button>
            </Link>
          </Box>
          <Box className="points-wrapper">
            <Box className="point-content1">
              <DoneIcon sx={{ fill: `${pointsColor}` }} />
              <Typography variant="span" style={{ color: `${pointsColor}` }}>
                14-day free trial
              </Typography>
            </Box>
            <Box className="point-content1">
              <DoneIcon sx={{ fill: `${pointsColor}` }} />
              <Typography variant="span" style={{ color: `${pointsColor}` }}>
                Full features enabled
              </Typography>
            </Box>
            <Box className="point-content1">
              <DoneIcon sx={{ fill: `${pointsColor}` }} />
              <Typography variant="span" style={{ color: `${pointsColor}` }}>
                No credit card is required
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

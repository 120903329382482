import React from "react";
import { Box, Typography } from "@mui/material";
import "../../styles/Secondary.scss";

const Secondary = (props) => {
  return (
    <Box className={props.class}>
      <Box className="imageFeatures">
        <img src={props.image} alt="" />
      </Box>
      <Box className="contentFeatures">
        <Box className="contentFeaturesHeading">
          <Typography variant="h2">{props.title}</Typography>
        </Box>
        <Typography variantMapping="p">{props.paragraph}</Typography>
      </Box>
    </Box>
  );
};

export default Secondary;
